:root {
  /* Primary colors */
  --color-primary-default: #4855c6;
  --color-primary-default-hsl: 234 53% 53%;
  --color-primary-darker: #313a87;
  --color-primary-highlight: #e9ebf8;
  --color-primary-background: #f8f8fd;

  /* Grayscale colors */
  --color-grayscale-very-gray: #202557;
  --color-grayscale-very-gray-hsl: 235 46% 23%;
  --color-grayscale-gray: #676b8d;
  --color-grayscale-disabled-gray: #8b8ea8;
  --color-grayscale-disabled-light: #e4e5eb;
  --color-grayscale-white: #ffffff;
  --color-grayscale-background: #f6f6f8;

  /* Complimentary colors */
  --color-complimentary-red: #cf4457;

  /* Shadows */
  --shadow-default: 0 0 15px rgb(0 0 0 / 12%);
  --shadow-blue: 0 0 12px hsl(var(--color-grayscale-very-gray-hsl) / 8%);
  --shadow-blue-strong: 0px 0px 16px 0px hsl(var(--color-grayscale-very-gray-hsl) / 24%);

  /* Others */
  --header-height: 58px;

  @media (--laptop) {
    --header-height: 64px;
  }
}

body {
  font-family: var(--font-open-sans), sans-serif;
}
